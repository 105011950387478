<style scoped lang="less">
.coq_stu_detail {
  .coq_detail_container {
    padding: 20px;

    .svg-div {
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .coq_detail_title {
      font-size: 20px;
      height: 50px;
      line-height: 50px;
    }

    .coq_detail_rows_1 {
      .el-col {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .label {
          color: #aaa;
        }

        .value {
          color: @fontColor;
        }
      }
      .flex-start {
        align-items: flex-start;
        .value {
          flex: 1;
          overflow: auto;
          max-width: 200px;
          .for {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }

    .coq_detail_rows_2 {
      margin-top: 20px;

      .el-col {
        .content {
          font-size: 18px;
        }
      }

      .coq_detail_func_col {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .coq_detail_func_ul {
          li {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            margin-left: 15px;
            color: #aaa;

            .coq_detail_func_ul_i {
              margin-right: 3px;
            }
          }

          li:not(:nth-child(2)) {
            cursor: pointer;
          }
        }
      }

      .coq_detail_reply {
        margin-top: 10px;
      }

      .coq_detail_reply_fun {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .coq_detail_reply_btn_left {
          .input_readonly {
            width: 150px;
            margin-left: 10px;
          }

          .image-upload {
            margin-right: 10px;
          }
        }

        .coq_detail_reply_btn_right {
        }
      }

      .coq_detail_my_reply {
        color: #999;
        padding: 15px 0;
        border-bottom: 1px solid #eee;

        .coq_detail_my_reply_div {
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .coq_detail_my_reply_btn {
            margin-top: 10px;
            display: flex;
            align-items: center;

            > div {
              display: flex;
              align-items: center;
              cursor: pointer;

              .svg-div {
                line-height: 16px;
                margin-right: 5px;
              }
            }

            i {
              cursor: pointer;
            }
          }
        }
      }
    }

    .coq_detail_func_li_active {
      color: @mainColor;
    }
  }
}
</style>
<template>
  <el-drawer
    :before-close="handleClose"
    custom-class="coq_stu_detail"
    title=""
    size="50%"
    :visible.sync="drawer"
  >
    <div class="coq_detail_container" v-loading="loading">
      <template v-if="items">
        <div style="margin-bottom: 10px">
          <el-button type="primary" @click="multi(items.topFlag, 'top')"
            >置顶/取消
          </el-button>
          <el-button type="primary" @click="multi(items.greateFlag, 'greate')"
            >精华/取消
          </el-button>
          <el-button type="primary" @click="multi(items.lockFlag, 'lock')"
            >锁定/取消
          </el-button>
          <el-button
            type="primary"
            @click="multi(0, 'del')"
            icon="el-icon-delete"
            >删除
          </el-button>
        </div>
        <div class="coq_detail_title">{{ items.title }}</div>
        <el-row class="coq_detail_rows_1">
          <el-col :sm="24" :md="12">
            <p class="label">发布人：</p>
            <p class="value">{{ items.name }}</p>
          </el-col>
          <el-col :sm="24" :md="12">
            <p class="label">状态：</p>
            <p class="value">
              {{ status(items.topFlag, items.greateFlag, items.lockFlag) }}
            </p>
          </el-col>
          <el-col :sm="24" :md="12">
            <p class="label">时间：</p>
            <p class="value">{{ publishTime }}</p>
          </el-col>
          <el-col :sm="24" :md="12" class="flex-start">
            <p class="label">资源文件：</p>
            <p class="value">
              <el-button
                v-if="items.eduCourseResources.length"
                type="text"
                @click="lookResource(items.eduCourseResources)"
                >查看</el-button
              >
              <span v-else>暂无资源</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="coq_detail_rows_2">
          <el-col :span="24">
            <span class="content">{{ items.content }}</span>
            <div style="margin-top: 20px">
              <viewer :images="items.eduAnwResList">
                <img
                  style="margin-left: 10px; width: 100px; height: 100px"
                  v-for="(src, index) in items.eduAnwResList"
                  :src="src.resourceUrl"
                  :key="index"
                />
              </viewer>
            </div>
          </el-col>
          <el-col :span="24" class="coq_detail_func_col">
            <el-button type="primary" @click="replySwitch = !replySwitch">
              我要回复
            </el-button>
            <ul class="coq_detail_func_ul">
              <li
                @click="replySwitch = !replySwitch"
                :class="replySwitch ? 'coq_detail_func_li_active' : ''"
              >
                <i class="el-icon-chat-dot-round coq_detail_func_ul_i"></i>
                <span>回复({{ items.ansUum }})</span>
              </li>
              <li>
                <div class="svg-div coq_detail_func_ul_i">
                  <svg-icon name="eyes"></svg-icon>
                </div>
                <span>{{ items.browseNum }}</span>
              </li>
              <li @click="onLike(items)">
                <!--                  :class="items.status === 0 ? 'coq_detail_func_li_active' : ''"-->
                <!--              >-->
                <div class="svg-div coq_detail_func_ul_i">
                  <svg-icon name="like" v-if="items.status === 1"></svg-icon>
                  <svg-icon
                    name="likeTrue"
                    v-if="items.status === 0"
                  ></svg-icon>
                </div>
                <span>{{ items.fabuloNum }}</span>
              </li>
            </ul>
          </el-col>

          <!-- 回复 -->
          <el-col :span="24" class="coq_detail_reply" v-show="replySwitch">
            <el-input
              type="textarea"
              v-model="reply"
              placeholder="请输入回复内容"
              :autosize="{ minRows: 5, maxRows: 6 }"
              :maxlength="250"
              show-word-limit
            ></el-input>
          </el-col>
          <el-col :span="24" class="coq_detail_reply_fun" v-show="replySwitch">
            <div class="coq_detail_reply_btn_left">
              <el-popover placement="top" trigger="click">
                <el-upload
                  list-type="picture-card"
                  action="#"
                  :on-change="onChange"
                  ref="upload"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <!-- <span
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span> -->
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-button type="primary" class="image-upload" slot="reference"
                  >图片上传
                </el-button>
              </el-popover>

              <el-button type="primary" @click="onSelectVideo">
                选择课程音视频
              </el-button>
              <el-input
                readonly
                v-model="selectCourseVideo.resourceName"
                class="input_readonly"
                size="mini"
              ></el-input>
            </div>
            <div class="coq_detail_reply_btn_right">
              <el-button @click="replySwitch = !replySwitch">取消</el-button>
              <el-button @click="onReply" :loading="btnLoading" type="primary"
                >发布
              </el-button>
            </div>
          </el-col>
          <el-col
            :span="24"
            v-for="(vos, i) in items.eduAnsweringVos"
            :key="i"
            class="coq_detail_my_reply"
          >
            <div style="margin-bottom: 10px">
              {{ vos.name }}:<span style="color: #606266; font-weight: 500">{{
                vos.content
              }}</span>
            </div>
            <div style="margin-top: 20px">
              <viewer :images="vos.eduAnwResList">
                <img
                  style="margin-left: 10px; width: 100px; height: 100px"
                  v-for="(src, index) in vos.eduAnwResList"
                  :src="src.resourceUrl"
                  :key="index"
                />
              </viewer>
            </div>
            <div class="coq_detail_my_reply_div">
              <p>时间：{{ dateFormatter(vos.publishTime) }}</p>
              <p style="width: 200px">
                资源文件：
                <el-button
                  v-if="vos.eduCourseResources.length"
                  type="text"
                  @click="lookResource(vos.eduCourseResources)"
                  >查看</el-button
                >
                <span v-else>暂无资源</span>
              </p>
              <div class="coq_detail_my_reply_btn">
                <el-icon
                  class="el-icon-delete"
                  @click.native="delReply(vos)"
                ></el-icon>
                <div style="margin: 0 10px">
                  <div class="svg-div coq_detail_func_ul_i">
                    <svg-icon name="eyes"></svg-icon>
                  </div>
                  <span>{{ vos.browseNum }}</span>
                </div>
                <div @click="onLike(vos)">
                  <!--                    :class="vos.status === 0 ? 'coq_detail_func_li_active' : ''"-->
                  <!--                >-->
                  <div class="svg-div coq_detail_func_ul_i">
                    <svg-icon name="like" v-if="vos.status === 1"></svg-icon>
                    <svg-icon
                      name="likeTrue"
                      v-if="vos.status === 0"
                    ></svg-icon>
                  </div>
                  <span>{{ vos.fabuloNum }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <CourseResources
      :courseId="course.id"
      @selectResources="selectResources"
      ref="courseResourcesRef"
    />
    <!-- 资源文件 -->
    <el-dialog
      title="资源文件"
      :visible.sync="resourceDialog"
      append-to-body
      @close="closeResourceDialog"
    >
      <div v-for="(item, index) in resourceList" :key="index">
        <ResourceVideo
          v-if="
            item.resourceUrl &&
            item.resourceUrl.substr(
              item.resourceUrl.length - 4,
              item.resourceUrl.length
            ) === '.mp4'
          "
          :url="item.resourceUrl"
        />
        <p v-else-if="item.resourceUrl">
          <el-link type="primary" :href="item.resourceUrl" target="_blank">{{
            item.resourceUrl
          }}</el-link>
        </p>
        <p v-else>暂无资源</p>
      </div>
    </el-dialog>
  </el-drawer>
</template>

<script>
import ResourceVideo from "./ResourceVideo.vue";
import CourseResources from "./CourseResources.vue";
import { dateTimeFormat } from "@/tools/date";
import {
  FabulousEduAnsweringByIdAndTea,
  QueryRecursionAllEduAnswering,
  UpdateEduAnswering,
  AddAllEduAnswering,
} from "@/libs/api/course";
export default {
  name: "coq_stu_detail",
  props: ["id"],
  components: { CourseResources, ResourceVideo },
  data() {
    return {
      drawer: false,
      loading: false,
      btnLoading: false,
      replySwitch: false, // 回复开关
      likeSwitch: false, // 点赞标记

      reply: null, // 回复内容
      fileList: [],
      items: null,
      eduCourseResources: [], // 课程资源集合
      // 选择的音视频资源
      selectCourseVideo: {
        title: null,
      }, // 选择的课程音视频名称
      dialogVisible: false,
      dialogImageUrl: null, // 预览图片

      resourceDialog: false, // 资源文件浏览开关
      resourceList: [],
    };
  },
  computed: {
    status() {
      // 置顶，精华，锁定
      return (topFlag, greateFlag, lockFlag) => {
        let arr = [];
        if (topFlag === 1) arr.push("置顶");
        if (greateFlag === 1) arr.push("精华");
        if (lockFlag === 1) arr.push("锁定");

        return arr.length ? arr.join() : "-";
      };
    },
    publishTime() {
      return this.items.publishTime
        ? dateTimeFormat(this.items.publishTime)
        : "-";
    },
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
    dateFormatter() {
      return (time) => {
        return time ? dateTimeFormat(time) : "";
      };
    },
  },
  watch: {
    id(n) {
      this.loading = true;
      this.likeSwitch = false;
      n && this.detailInit();
    },
  },
  methods: {
    handleClose(done) {
      // this.$emit("closeReturn");
      done();
    },
    onSelectVideo() {
      this.$refs["courseResourcesRef"].dialog = true;
    },
    // 选择的课程音视频
    selectResources(item) {
      this.selectCourseVideo = item;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 置顶/精华/锁定/删除
    multi(num, flag) {
      let msg;
      if (num === 0) {
        msg = `${
          flag === "top"
            ? "置顶"
            : flag === "greate"
            ? "加精"
            : flag === "lock"
            ? "锁定"
            : "删除"
        }`;
      }
      UpdateEduAnswering({
        ids: this.id, // 答疑id
        topFlag: flag === "top" && num === 0 ? 1 : 0, //置顶标识 0否 1是
        greateFlag: flag === "greate" && num === 0 ? 1 : 0, //精华标识 0否 1是
        lockFlag: flag === "lock" && num === 0 ? 1 : 0, //锁定标识 0否 1是
        delFlag: flag === "del" ? 1 : 0, //删除标识：0否，1是
      }).then((res) => {
        this.$message.success(`${msg || "取消"}${res.message}`);
        if (flag === "del") {
          this.drawer = false;
        }
        this.detailInit();
        this.$emit("initParent");
      });
    },
    onLike(item) {
      // if (item.status == 0) {
      //   return;
      // }
      // this.likeSwitch = !this.likeSwitch;
      FabulousEduAnsweringByIdAndTea({
        userId: this.user.id,
        userType: 0,
        awId: item.id,
      }).then((res) => {
        // this.likeSwitch = true;
        this.detailInit();
      });
    },
    delReply(item) {
      if (item) {
        this.$confirm("确定要删除吗?", "删除提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            const data = {
              ids: item.id,
              delFlag: 1,
              delType: item.delType,
            };
            UpdateEduAnswering(data).then((res) => {
              this.$message.success("删除成功");
              this.$emit("initParent");
              this.detailInit();
            });
          })
          .catch(() => {});
      }
    },
    onReply() {
      if (!this.reply) return this.$message.error("请输入回复内容");
      //   if (!this.fileList.length) return this.$message.error("请选择图片");
      let data = new FormData();
      if (this.fileList.length) {
        this.fileList.map((item) => data.append("files", item.raw));
      }
      data.append("courseId", this.course.id);
      data.append("title", this.items.title);
      data.append("content", this.reply);
      data.append("publishId", this.user.id); // 发布人
      data.append("qaType", 0); // 发布人类型 0老师 1学生
      data.append("faId", this.items.id); // 一级答疑 发布传0 回复传父id
      if (this.selectCourseVideo.id) {
        data.append("courseResId", this.selectCourseVideo.id); // 资源文件id
      }
      this.btnLoading = true;
      AddAllEduAnswering(data)
        .then((res) => {
          this.btnLoading = false;
          if (res.code === 2000) {
            this.$message.success("发布成功");
            this.reply = "";
            this.$refs.upload.clearFiles();
            this.$emit("initParent");
            this.detailInit();
            return;
          }
          this.$message.error(res.message);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    detailInit() {
      QueryRecursionAllEduAnswering({ id: this.id, type: 0 })
        .then((res) => {
          this.loading = false;
          this.items = res.data;
          this.eduCourseResources = res.data.eduCourseResources;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 下载
    handleDownload(file) {},
    // 删除图片
    handleRemove(file) {
      this.fileList.forEach((item, index) => {
        if (item.uid === file.uid) this.fileList.splice(index, 1);
      });
    },
    lookResource(list) {
      this.resourceList = list;
      this.resourceDialog = true;
    },

    closeResourceDialog() {
      setTimeout(() => {
        this.resourceList = [];
      }, 300);
    },
  },
};
</script>
