<template>
  <el-dialog
    :visible.sync="dialog"
    title="课程资源"
    custom-class="student_customDialogStyle coq_detail_resources_dialog"
    append-to-body
  >
    <div class="coq_detail_resources_dialog_header">
      <span>名称</span>
      <el-input v-model="queryText" placeholder="请输入"></el-input>
      <el-button type="primary" @click="init">查询</el-button>
    </div>
    <el-table
      :data="datalist"
      :maxHeihgt="250"
      :height="250"
      @current-change="currentChange"
    >
      <el-table-column align="center" width="60">
        <template slot-scope="scope">
          <el-radio
            v-model="radio"
            :label="scope.row.resourceName + '_' + scope.row.createDate"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column
        label="标题"
        prop="resourceName"
        :min-width="180"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column
        label="文件大小"
        prop="resourceSize"
        align="center"
      ></el-table-column>
      <el-table-column
        label="资源类型"
        prop="resourceType"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.resourceType==1">文档</span>
          <span v-if="scope.row.resourceType==2">音频</span>
          <span v-if="scope.row.resourceType==3">视频</span>
          <span v-if="scope.row.resourceType==4">图片</span>
          <span v-if="scope.row.resourceType==5">网页</span>
          <span v-if="scope.row.resourceType==6">压缩包</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="createDate"
        :min-width="120"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialog = false">取 消</el-button>
      <el-button type="primary" @click="select">选择并关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
// 选择课程资源
import { QueryCourseResources } from "@/libs/api/course";
export default {
  name: "coq_detail_resources_dialog",
  props: ["courseId"],
  data() {
    return {
      dialog: false,
      tableData: [],
      queryText: null,
      pageSize: 10,
      current: 1,
      total: 0,
      radio: null,
      selectRow: null,
    };
  },
  watch: {
    dialog(n) {
      if (n) {
        this.current = 1;
        this.init();
      }
    },
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
  },
  methods: {
    init() {
      QueryCourseResources({
        courseId: this.courseId,
        title: this.queryText,
      }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    select() {
      if (!this.selectRow) return this.$message.error('请选择数据');
      this.dialog = false;
      this.$emit("selectResources", this.selectRow);
    },

    currentChange(val) {
      if (val) {
        this.radio = val.resourceName + "_" + val.createDate;
        this.selectRow = val;
      }
    },
  },
};
</script>

<style  lang="less">
.coq_detail_resources_dialog {
  .coq_detail_resources_dialog_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .el-input {
      width: 200px;
      margin: 0 10px;
    }
  }
}
</style>