<template>
  <div class="c_online_questions_form">
    <el-form :model="form" size="medium" inline>
      <el-form-item prop="title" label="标题/发布人">
        <el-input
          v-model="form.title"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="date" label="发布时间">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "c_online_questions_form",
  data() {
    return {
      form: {
        title: null,
        date: [],
      },
    };
  },
  methods: {
    query() {
      this.$emit("questions_query", { ...this.form });
    },
  },
};
</script>

<style scope lang="less">
.c_online_questions_form {
  padding: 0 20px;
}
</style>