
<style scoped lang="less">
.coq_release {
  .stu-release-form {
    padding: 20px;
    .stu-release-drawer-btn {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .input_readonly {
        width: 150px;
        margin-left: 10px;
      }
    }
  }
}
</style>
<template>
  <el-drawer
    custom-class="coq_release"
    title=""
    size="50%"
    :visible.sync="drawer"
  >
    <el-form
      :model="form"
      class="stu-release-form"
      :rules="rules"
      ref="stuReleaseForm"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          size="small"
          v-model="form.title"
          :maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="content">
        <el-input
          size="small"
          type="textarea"
          v-model="form.content"
          :autosize="{ minRows: 4, maxRows: 6 }"
          :maxlength="250"
          show-word-limit
        ></el-input>
      </el-form-item>
      <div class="stu-release-drawer-btn">
        <div style="margin-top:10px;min-width: 380px;">
          <el-popover placement="bottom" trigger="click">
            <el-upload
              class="upload-demo"
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              ref="upload"
              :on-change="handleChange"
              :file-list="form.fileList"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  style="width: 100%; height: 146px"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <!-- <span
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span> -->
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
              <!-- <el-button size="small" type="primary">图片上传</el-button> -->
            </el-upload>
            <p style="color: red;font-size: 14px; margin-left:10px;margin-top: 10px; font-weight: bold;">仅支持jpg,png格式</p>
            <el-button
              type="primary"
              style="margin-right: 10px"
              slot="reference"
              >图片上传</el-button>
          </el-popover>

          <el-button type="primary" @click="onSelectVideo">
            选择课程音视频
          </el-button>
          <el-input
            readonly
            v-model="selectCourseVideo.resourceName"
            class="input_readonly"
            size="mini"
          ></el-input>
        </div>

        <div style="margin-top:10px;min-width: 150px">
          <el-button type="primary" @click="handleConfirm('stuReleaseForm')"
            >立即创建</el-button
          >
          <el-button @click="drawer = false">取消</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <CourseResources
      :courseId="course.id"
      @selectResources="selectResources"
      ref="courseResourcesRef"
    />
  </el-drawer>
</template>

<script>
import CourseResources from "./CourseResources.vue";
export default {
  name: "coq_release",
  components: { CourseResources },
  data() {
    return {
      drawer: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入简介", trigger: "blur" }],
      },
      form: {
        title: null,
        content: null,
        fileList: [],
      },
      dialogImageUrl: "",
      dialogVisible: false,

      selectCourseVideo: {
        title: null,
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  methods: {
    handleChange(file, fileList) {
      // this.form.fileList.push(file.raw);
      this.form.fileList = fileList;
    },
    handleConfirm(refName) {
      if (this.selectCourseVideo.id) {
        this.form.courseResId = this.selectCourseVideo.id; // 资源文件id
      }
      this.$refs[refName].validate((valid) => {
        if (valid) {
          this.$emit("send", this.form);
          this.form = {
            title: null,
            content: null,
            fileList: [],
          };
        }
        this.drawer = false;
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      let url = URL.createObjectURL(file.raw);
      window.open(url);
    },
    // 删除选中图片
    handleRemove(file) {
      this.form.fileList.forEach((item, index) => {
        if (item.uid === file.uid) this.form.fileList.splice(index, 1);
      });
    },
    // 选择资源文件
    onSelectVideo() {
      this.$refs["courseResourcesRef"].dialog = true;
    },
    // 选择的课程音视频
    selectResources(item) {
      this.selectCourseVideo = item;
    },
  },
};
</script>
