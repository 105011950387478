<template>
  <div class="t_question_online">
    <div class="stu-module-header">
      <div class="stu-module-title">在线答疑</div>
      <div>
        <el-button type="primary" @click="add" icon="el-icon-plus">
          发帖
        </el-button>
        <el-button type="primary" @click="top">置顶/取消</el-button>
        <el-button type="primary" @click="like">精华/取消</el-button>
        <el-button type="primary" @click="block">锁定/取消</el-button>
        <el-button type="primary" @click="del" icon="el-icon-delete">
          删除
        </el-button>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <QuestionsForm @questions_query="questions_query" />
      <el-tab-pane label="全部问题" name="one">
        <div class="table-group">
          <el-table
            :data="tableData"
            :max-height="450"
            :height="450"
            highlight-current-row
            @selection-change="selectionChange"
          >
            <el-table-column :width="60" align="center" type="selection">
            </el-table-column>
            <el-table-column label="序号" :width="60" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column
              label="标题"
              prop="title"
              :minWidth="200"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <!-- plain dark -->
                <el-tag v-if="scope.row.del" size="mini" effect="dark"
                  >已删除</el-tag
                >
                <el-button
                  @click="detail(scope.row, scope.$index)"
                  type="text"
                  >{{ scope.row.title }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-for="(c, i) in col"
              :key="i"
              :label="c.label"
              :prop="c.prop"
              :minWidth="c.width"
              :show-overflow-tooltip="c.tooltip"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(scope.row, scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="精华帖子" name="two">
        <div class="table-group">
          <el-table
            :data="tableData"
            :max-height="450"
            :height="450"
            highlight-current-row
            @selection-change="selectionChange"
          >
            <el-table-column :width="60" align="center" type="selection">
            </el-table-column>
            <el-table-column label="序号" :width="60" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column
              label="标题"
              prop="title"
              :minWidth="200"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <!-- plain dark -->
                <el-tag v-if="scope.row.del" size="mini" effect="dark"
                  >已删除</el-tag
                >
                <el-button
                  @click="detail(scope.row, scope.$index)"
                  type="text"
                  >{{ scope.row.title }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-for="(c, i) in col"
              :key="i"
              :label="c.label"
              :prop="c.prop"
              :minWidth="c.width"
              :show-overflow-tooltip="c.tooltip"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(scope.row, scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="current2"
            :page-size="pageSize2"
            layout="total, prev, pager, next, jumper"
            :total="total2"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我的问题" name="three">
        <div class="table-group">
          <el-table
            :data="tableData"
            :max-height="450"
            :height="450"
            highlight-current-row
            @selection-change="selectionChange"
          >
            <el-table-column :width="60" align="center" type="selection">
            </el-table-column>
            <el-table-column label="序号" :width="60" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column
              label="标题"
              prop="title"
              :minWidth="200"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <!-- plain dark -->
                <el-tag v-if="scope.row.del" size="mini" effect="dark"
                  >已删除</el-tag
                >
                <el-button
                  @click="detail(scope.row, scope.$index)"
                  type="text"
                  >{{ scope.row.title }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-for="(c, i) in col"
              :key="i"
              :label="c.label"
              :prop="c.prop"
              :minWidth="c.width"
              :show-overflow-tooltip="c.tooltip"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(scope.row, scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total3"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="我的回复" name="four">
        <div class="table-group">
          <el-table
            :data="tableData"
            :max-height="450"
            :height="450"
            highlight-current-row
            @selection-change="selectionChange"
          >
            <el-table-column :width="60" align="center" type="selection">
            </el-table-column>
            <el-table-column label="序号" :width="60" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column
              label="标题"
              prop="title"
              :minWidth="200"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <!-- plain dark -->
                <el-tag v-if="scope.row.del" size="mini" effect="dark"
                  >已删除</el-tag
                >
                <el-button
                  @click="detail(scope.row, scope.$index)"
                  type="text"
                  >{{ scope.row.title }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-for="(c, i) in col"
              :key="i"
              :label="c.label"
              :prop="c.prop"
              :minWidth="c.width"
              :show-overflow-tooltip="c.tooltip"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(scope.row, scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total4"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="未回复" name="five">
        <div class="table-group">
          <el-table
            :data="tableData"
            :max-height="450"
            :height="450"
            highlight-current-row
            @selection-change="selectionChange"
          >
            <el-table-column :width="60" align="center" type="selection">
            </el-table-column>
            <el-table-column label="序号" :width="60" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column
              label="标题"
              prop="title"
              :minWidth="200"
              :show-overflow-tooltip="true"
              align="center"
            >
              <template slot-scope="scope">
                <!-- plain dark -->
                <el-tag v-if="scope.row.del" size="mini" effect="dark"
                  >已删除</el-tag
                >
                <el-button
                  @click="detail(scope.row, scope.$index)"
                  type="text"
                  >{{ scope.row.title }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-for="(c, i) in col"
              :key="i"
              :label="c.label"
              :prop="c.prop"
              :minWidth="c.width"
              :show-overflow-tooltip="c.tooltip"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="onDelete(scope.row, scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            background
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total5"
          >
          </el-pagination></div
      ></el-tab-pane>
    </el-tabs>

    <Release ref="releaseRef" @send="send($event)" />

    <Detail
      :id="rowId"
      @closeReturn="closeReturn"
      @initParent="initParent"
      ref="detailRef"
    />
  </div>
</template>

<script>
import QuestionsForm from "./module/Form.vue";
import Release from "./module/Release.vue";
import Detail from "./module/Detail.vue";
export default {
  name: "t_question_online",
  components: { QuestionsForm, Release, Detail },
  data() {
    return {
      rowId: null,
      activeName: "one",
      tableData: [],
      col: [
        { label: "发布时间", prop: "createTime", tooltip: true, width: 120 },
        { label: "发布人", prop: "name" },
        { label: "浏览次数", prop: "browseNum" },
        { label: "回复次数", prop: "ansUum" },
        { label: "最后回复人", prop: "lastName" },
      ],
      selectionData: [], // 选中的多选框
      selectRow: null,
      current: 1,
      pageSize: 10,
      total: 0,
      current2: 1,
      pageSize2: 10,
      total2: 0,
      total3: 0,
      total4: 0,
      total5: 0,
      bo: {
        page: 1,
        pageSize: 10,
        greateFlag: 1, // 精华标识 0否 1是
        content: null, //发布人标题
        startTime: "", //开始时间(yyyy/mm/dd hh:mm:ss)
        endTime: "", //结束时间(yyyy/mm/dd hh:mm:ss)
      },
      index: null,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  activated() {
    if (this.course) this.query();
  },
  methods: {
    closeReturn() {
      if (this.course) this.query();
    },
    send(row) {
      let formData = new FormData();
      if (row.courseResId) {
        formData.append("courseResId", row.courseResId); // 资源文件id
      }
      formData.append("courseId", this.course.id);
      formData.append("title", row.title);
      formData.append("publishId", this.user.id);
      formData.append("qaType", "0");
      formData.append("faId", "0");
      formData.append("content", row.content);
      row.fileList.forEach((item) => {
        formData.append("files", item.raw);
      });
      this.$axios_supermall
        .post("/hnjxjy-core/eduAnswering/addAllEduAnswering", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success("发布成功");
            this.query();
          }
        });
      this.$refs["releaseRef"].drawer = false;
    },
    query() {
      this.openLoadingView();
      let formData = new FormData();
      formData.append("page", this.bo.page);
      formData.append("pageSize", this.bo.pageSize);
      //精华
      if (this.index == "1") {
        formData.append("greateFlag", "1");
      }
      //我的问题
      if (this.index == "2") {
        formData.append("publishId", this.user.id);
      }
      if (this.index == "3") {
        formData.append("type", "3");
        formData.append("publishId", this.user.id);
      }
      if (this.index == "4") {
        formData.append("type", "2");
      }
      if (this.bo.content) {
        formData.append("content", this.bo.content);
      }
      if (this.bo.startTime != null && this.bo.startTime != "") {
        formData.append("startTime", this.bo.startTime);
        formData.append("endTime", this.bo.endTime);
      }

      formData.append("courseId", this.course.id);
      formData.append("qaType", "0");
      this.$axios_supermallData
        .post("/hnjxjy-core/eduAnswering/queryAllEduAnswering", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
            if (this.index == "1") {
              this.total2 = resp.data.data.total;
            } else if (this.index == "2") {
              this.total3 = resp.data.data.total;
            } else if (this.index == "3") {
              this.total4 = resp.data.data.total;
            } else if (this.index == "4") {
              this.total5 = resp.data.data.total;
            }
          }
          this.loadingView.close();
        })
        .catch(() => {
          this.loadingView.close();
        });
    },
    initParent() {
      this.query();
    },
    add() {
      this.$refs["releaseRef"].drawer = true;
    },
    top() {
      if (this.selectionData.length === 0) {
        return this.$message.warning("请选择数据");
      }
      this.updateAll("1");
    },

    like() {
      if (this.selectionData.length === 0) {
        return this.$message.warning("请选择数据");
      }
      this.updateAll("2");
    },
    block() {
      if (this.selectionData.length === 0) {
        return this.$message.warning("请选择数据");
      }
      this.updateAll("3");
    },
    del() {
      if (this.selectionData.length === 0) {
        return this.$message.warning("请选择数据");
      }
      this.updateAll("4");
    },
    // 复选框选中
    selectionChange(val) {
      this.selectionData = val;
    },
    updateAll(type) {
      let bo = {
        ids: this.selectionData.map((m) => m.id).join(),
        topFlag: 0, //置顶标识 0否 1是
        greateFlag: 0, // 精华标识 0否 1是
        lockFlag: 0, //锁定标识 0否 1是
        delFlag: 0, //删除标识：0否，1是
      };
      // 1置顶 2精华 3锁定 4删除
      const key =
        type === "1"
          ? "topFlag"
          : type === "2"
          ? "greateFlag"
          : type === "3"
          ? "lockFlag"
          : "delFlag";

      // 若选中的集合中 key 存在“0否”的，因为是多选，存在一个则全部传1，不存在全部传0
      const filterMap = this.selectionData
        .filter((f) => f[key] === 0)
        .map((m) => m[key]);

      if (filterMap.length) bo[key] = 1;

      this.$axios_supermall
        .post("/hnjxjy-core/eduAnswering/updateEduAnswering", bo)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功");
            this.query();
          }
        });
    },

    // 查询
    questions_query(item) {
      this.bo.content = item.title;
      this.bo.startTime = null;
      this.bo.endTime = null;
      if (item.date != null && item.date.length > 0) {
        this.bo.startTime = item.date[0].formatToLocalDateTime(
          "yyyy/MM/dd HH:mm:ss"
        );
        this.bo.endTime = item.date[1].formatToLocalDateTime(
          "yyyy/MM/dd HH:mm:ss"
        );
      }
      // if (this.index == "1") {
      //   this.query("1");
      // } else if (this.index == "2") {
      //   this.query("2");
      // } else if (this.index == "3") {
      //   this.query("3");
      // } else if (this.index == "4") {
      //   this.query("4");
      // } else {
      //   this.query();
      // }
      this.query();
    },
    handleClick(tab) {
      this.index = tab.index;
      // if (tab.index == "0") {
      //   this.query("0");
      // } else if (tab.index == "1") {
      //   this.query("1");
      // } else if (tab.index == "2") {
      //   this.query("2");
      // } else if (tab.index == "3") {
      //   this.query("3");
      // } else if (tab.index == "4") {
      //   this.query("4");
      // }
      this.query();
    },

    handleCurrentChange(page) {
      this.bo.page = page;
      this.query();
    },

    handleSizeChange(val) {
      this.bo.pageSize = val;
      this.query();
    },
    handleCurrentChange2(page) {
      this.bo.page = page;
      this.query();
    },

    handleSizeChange2(val) {
      this.bo.pageSize = val;
      this.query();
    },
    detail(row, index) {
      if (row) {
        this.rowId = row.id;
        this.$refs["detailRef"].drawer = true;
      }
    },
    onDelete(row, index) {
      let bo = {
        ids: row.id,
        delFlag: 1, //删除标识：0否，1是
      };
      this.$confirm("确定要删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios_supermall
            .post("/hnjxjy-core/eduAnswering/updateEduAnswering", bo)
            .then((resp) => {
              if (resp.data.code == 2000) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.query();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.t_question_online {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
}
</style>
